import React from "react";
import Delievery from "./Delievery";
import Payment from "./Payment";

const PaymentAnddelievery = () => {
  return (
    <>
      <div className="">
        <Payment />
        <Delievery />
      </div>
    </>
  );
};

export default PaymentAnddelievery;
